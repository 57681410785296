import { franc } from 'franc';
import React, { useEffect, useMemo, useState } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const MarkDownComp = ({ text }: { text: string }) => {
  const [direction, setDirection] = useState<'ltr' | 'rtl'>('ltr');
  const textAlign = useMemo(() => (direction === 'rtl' ? 'right' : 'left'), [direction]);

  useEffect(() => {
    const langCode = franc(text, { minLength: 1 });
    // Set the direction based on the language code
    if (langCode === 'heb' || langCode === 'arb') {
      // Hebrew or Arabic
      setDirection('rtl');
    } else {
      setDirection('ltr');
    }
  }, [text]);

  return (
    <div className="max-w-[70%] w-fit bg-slate-100 rounded-md px-2 py-0">
      <Markdown
        remarkPlugins={[remarkGfm]}
        children={text}
        components={{
          // Handling paragraph elements to control spacing
          p: ({ node, children, ...props }) => (
            <p
              className="m-0 whitespace-pre-line mt-[7px] mb-[7px] p-2 rounded-md"
              style={{ direction: direction, textAlign: textAlign }}
              {...props}>
              {children}
            </p> // Tailwind class for margin, adjust as needed
          ),
          a: ({ node, children, href, ...props }) => (
            <a href={href} style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          ),
          ol: ({ node, ...props }) => (
            <ol
              style={{
                margin: '0.5rem 1rem',
                listStyleType: 'decimal',
                direction: direction,
                textAlign: textAlign
              }}
              {...props}
            />
          ),
          // Custom renderer for unordered lists
          ul: ({ node, ...props }) => (
            <ul
              style={{
                margin: '0.5rem 1rem',
                listStyleType: 'disc',
                direction: direction,
                textAlign: textAlign
              }}
              {...props}
            />
          ),
          // Custom renderer for list items
          li: ({ node, ...props }) => (
            <li
              style={{
                padding: '0rem 0.5rem', // Consistent padding for list items
                margin: 0,
                direction: direction,
                textAlign: textAlign
              }}
              {...props}
            />
          ),
          // Handling code blocks and inline code
          code: ({ node, className, children, ...props }) => {
            const isBlockCode = className?.includes('language-');
            const codeBaseClasses = 'break-words';
            const codeStyle = {
              tabSize: 4
            };

            if (!isBlockCode) {
              return (
                <code
                  style={codeStyle}
                  className={`${className} ${codeBaseClasses} p-1 rounded whitespace-pre-line overflow-hidden`}
                  {...props}>
                  {children}
                </code>
              );
            } else {
              return (
                <pre className="break-word">
                  <code
                    style={codeStyle}
                    className={`${className}  ${codeStyle} break-words whitespace-pre-wrap overflow-hidden`}
                    {...props}>
                    {children}
                  </code>
                </pre>
              );
            }
          }
        }}
      />
    </div>
  );
};

export default MarkDownComp;

import axios from 'axios';
// import { getSession } from 'next-auth/react';

// export const BASE_URL = 'https://aifusion-pelephone.k2view.com:5000'
// export const BASE_URL = 'http://127.0.0.1:5005';
export const BASE_URL = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
instance.interceptors.response.use(
  (response) => {
    // Modify the response data here

    return response;
  },
  (error) => {
    // Handle response errors here

    return Promise.reject(error);
  }
);

export default instance;

// src/context/AuthContext.js
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axiosinstance from './axiosInterceptorInstance';
import { Claude, GPT, Platform } from './PopOver';

const getPlatformFromSelected = (selectedValue) => {
  if (selectedValue.startsWith('claude-')) {
    return Platform.CLAUDE;
  } else if (selectedValue.startsWith('galil-')) {
    return Platform.AZURE;
  }
  return null;
};

const AuthContext = createContext<any>({ user: {} });

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({
  children
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const [user, setUser] = useState<any>({});
  const [error, setError] = useState('');
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [selectedModel, setSelectedModel] = useState<string>(Claude.OPUS);
  const [pathId, setPathId] = useState('');
  const modelProvider = useMemo(
    () =>
      selectedModel.startsWith('claude-')
        ? Platform.CLAUDE
        : selectedModel.startsWith('galil-')
          ? Platform.AZURE
          : null,
    [selectedModel]
  );

  const login = useCallback(async (number) => {
    const storedModel = sessionStorage.getItem('model');

    const model = storedModel || Claude.OPUS;
    const modelprov = getPlatformFromSelected(model);
    try {
      setIsLoadingLogin(true);
      const res = await axiosinstance.post('/session', {
        number: number,
        primary: {
          model: model,
          provider: modelprov
        },
        secondary: {
          model: model,
          provider: modelprov
        }
      });
      const { access_token, id } = res.data;
      if (access_token && id) {
        setUser({ access_token, id });
        // setCustomer(customer)
        setError('');
      } else {
        setError('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Login failed');
    } finally {
      setIsLoadingLogin(false);
    }
  }, []);

  useEffect(() => {
    const validate = async () => {
      const pathArray = window.location.pathname.split('/');
      const id = pathArray[1];

      const res = await axiosinstance.post('./validate', {
        id: id
      });
      const { validate } = res.data;
      setPathId(id);
      setError(validate ? '' : 'url id error');
    };
    validate();
  }, []);
  const logout = () => {
    setUser(null);
  };

  const isLoggedIn = useMemo(() => user.id && user.access_token, [user]);

  useEffect(() => {
    const model = sessionStorage.getItem('model');
    setSelectedModel(model || Claude.OPUS);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('model', selectedModel);
  }, [selectedModel]);

  useEffect(() => {
    //ensure opus is selected!
    setSelectedModel('claude-3-opus-20240229');
  }, []);

  const value = {
    user,
    error,
    login,
    logout,
    isLoadingLogin,
    isLoggedIn,
    selectedModel,
    modelProvider,
    setSelectedModel,
    pathId
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

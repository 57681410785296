
export const countryTranslationsHebrew  = {
    "Albania": "אלבניה",
    "Andorra": "אנדורה",
    "Aruba": "ארובה",
    "Benin": "בנין",
    "Bolivia": "בוליביה",
    "Burkina Faso": "בורקינה פאסו",
    "Bahamas": "איי בהאמה",
    "Cameroon": "קמרון",
    "Congo": "קונגו",
    "Congo Dr": "הרפובליקה הדמוקרטית של קונגו",
    "El Salvador": "אל סלבדור",
    "Ethiopia": "אתיופיה",
    "Gabon Republic": "הרפובליקה של גבון",
    "Ghana": "גאנה",
    "Guiana": "גיאנה",
    "Guinea": "גינאה",
    "Guineabissau": "גינאה-ביסאו",
    "Guadeloupe Islands": "איי גוואדלופ",
    "Haiti": "האיטי",
    "Kenya": "קניה",
    "Kyrgyzstan": "קירגיזסטן",
    "Madagascar": "מדגסקר",
    "Malawi": "מלאווי",
    "Martinique": "מרטיניק",
    "Monaco": "מונקו",
    "Mozambique": "מוזמביק",
    "Niger": "ניז'ר",
    "Nigeria": "ניגריה",
    "Rwanda": "רואנדה",
    "Seychelles": "סיישל",
    "Suriname": "סורינאם",
    "Swaziland": "סווזילנד",
    "Tajikistan": "טג'יקיסטן",
    "Tanzania": "טנזניה",
    "Trinidad And Tobago": "טרינידד וטובגו",
    "Uganda": "אוגנדה",
    "Unitedarabemirates": "איחוד האמירויות הערביות",
    "Vietnam": "וייטנאם",
    "Israel": "ישראל",
    "Zambia": "זמביה",
    "Zimbabwe": "זימבבואה",
    "Angola": "אנגולה",
    "Bhutan": "בהוטן",
    "Equatorial Guinea": "גינאה המשוונית",
    "Gambia": "גמביה",
    "Namibia": "נמיביה",
    "Argentina": "ארגנטינה",
    "Armenia": "ארמניה",
    "Australia": "אוסטרליה",
    "Austria": "אוסטריה",
    "Belgium": "בלגיה",
    "Brazil": "ברזיל",
    "Bulgaria": "בולגריה",
    "China": "סין",
    "Colombia": "קולומביה",
    "Croatia": "קרואטיה",
    "Cyprus": "קפריסין",
    "Czech Republic": "הרפובליקה הצ'כית",
    "Denmark": "דנמרק",
    "Dominican Republic": "הרפובליקה הדומיניקנית",
    "Ecuador": "אקוודור",
    "Estonia": "אסטוניה",
    "Finland": "פינלנד",
    "France": "צרפת",
    "Georgia": "גאורגיה",
    "Germany": "גרמניה",
    "Greece": "יוון",
    "Guatemala": "גואטמלה",
    "Honduras": "הונדורס",
    "Hongkong": "הונג קונג",
    "Hungary": "הונגריה",
    "India": "הודו",
    "Ireland": "אירלנד",
    "Italy": "איטליה",
    "Japan": "יפן",
    "Jordan": "ירדן",
    "Koreasouth": "דרום קוריאה",
    "Latvia": "לטביה",
    "Lithuania": "ליטא",
    "Luxembourg": "לוקסמבורג",
    "Macao": "מקאו",
    "Malta": "מלטה",
    "Mexico": "מקסיקו",
    "Netherlands": "הולנד",
    "Newzealand": "ניו זילנד",
    "Nicaragua": "ניקרגואה",
    "Norway": "נורווגיה",
    "Paraguay": "פרגוואי",
    "Peru": "פרו",
    "Philippines": "הפיליפינים",
    "Poland": "פולין",
    "Portugal": "פורטוגל",
    "Puerto Rico": "פוארטו ריקו",
    "Romania": "רומניה",
    "Russia": "רוסיה",
    "Serbia": "סרביה",
    "Singapore": "סינגפור",
    "Slovakia": "סלובקיה",
    "Slovenia": "סלובניה",
    "South Africa": "דרום אפריקה",
    "Spain": "ספרד",
    "Srilanka": "סרי לנקה",
    "Sweden": "שוודיה",
    "Switzerland": "שווייץ",
    "Taiwan": "טייוואן",
    "Thailand": "תאילנד",
    "Turkey": "טורקיה",
    "Ukraine": "אוקראינה",
    "United Kingdom": "הממלכה המאוחדת",
    "Azerbaijan": "אזרבייג'ן",
    "Anguilla": "אנגווילה",
    "Antigua And Barbuda": "אנטיגואה וברבודה",
    "Belarus": "בלארוס",
    "Barbados": "ברבדוס",
    "Bermuda": "ברמודה",
    "British Virgin Islan": "איי הבתולה הבריטיים",
    "Cambodia": "קמבודיה",
    "Chadrepublic": "הרפובליקה של צ'אד",
    "Costarica": "קוסטה ריקה",
    "Cayman Islands": "איי קיימן",
    "Dominica": "דומיניקה",
    "Faroe Islands": "איי פארו",
    "Gibraltar": "גיברלטר",
    "Guyana": "גיאנה",
    "Grenada And Carriaco": "גרנדה וקריאקו",
    "Iceland": "איסלנד",
    "Indonesia": "אינדונזיה",
    "Ivory Coast": "חוף השנהב",
    "Jamaica": "ג'מייקה",
    "Kazakhstan": "קזחסטן",
    "Liechtenstein": "ליכטנשטיין",
    "Macedonia": "מקדוניה",
    "Moldova": "מולדובה",
    "Mongolia": "מונגוליה",
    "Montenegro": "מונטנגרו",
    "Montserrat": "מונסראט",
    "Panama": "פנמה",
    "Saint Kitts And Nevi": "סנט קיטס ונוויס",
    "Saint Lucia": "סנט לוסיה",
    "Saint Vincent And Th": "סנט וינסנט והגרנדינים",
    "Turks And Caicos Isl": "איי טרקס וקייקוס",
    "U.S. Virgin Islands": "איי הבתולה של ארה\"ב",
    "Uruguay": "אורוגוואי",
    "Uzbekistan": "אוזבקיסטן",
    "Bahrain": "בחריין",
    "Belize": "בליז",
    "Botswana": "בוטסואנה",
    "Cape Verde Islands": "איי כף ורדה",
    "Fiji Islands": "איי פיג'י",
    "Frenchpolynesia": "פולינזיה הצרפתית",
    "Kosovo": "קוסובו",
    "Mauritius": "מאוריציוס",
    "Nauru": "נאורו",
    "Netherlands Antilles": "אנטילים ההולנדיים",
    "Papua": "פפואה",
    "Senegal": "סנגל",
    "Togo": "טוגו",
    "Tongaislands": "איי טונגה",
    "Vanuatu": "ונואטו",
    "Western Samoa": "סמואה המערבית",
    "Bosnia And Herzegov": "בוסניה והרצגובינה",
    "Laos": "לאוס",
    "Lesotho": "לסוטו",
    "Morocco": "מרוקו",
    "Nepal": "נפאל",
    "Burundi": "בורונדי",
    "Canada": "קנדה",
    "Chile": "צ'ילה",
    "Cuba": "קובה",
    "Liberia": "ליבריה",
    "Sierraleone": "סיירה לאון",
    "Egypt": "מצרים",
    "Usa": "ארצות הברית"
  };